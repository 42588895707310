import axios from "axios";
import { useContext } from "react";
import Context from "../../Context";
import "./index.scss";

const Form = (props) => {
    const { setModal } = useContext(Context);

    const handleSubmit = (e) => {
        e.preventDefault();

        let callback = props.callback ?? {
            afterSuccessSubmit: (response) => setModal({ titulo: "Obrigado!", texto: response.data.success, botao: "Voltar ao site" })
        };

        let formData = new FormData(e.target);
        let button = e.target.querySelector("button[type='submit']");

        button.setAttribute("disabled", "disabled");

        let endPoint = props.endPoint ?? 'formularios';

        axios.post(localStorage.getItem("apiUrl") + endPoint, formData, {

            headers: {
                'Content-Type': 'multipart/form-data'
            }

        }).then(response => {

            button.removeAttribute("disabled");

            if (response.data.status !== 200) {

                window.alert(response.data.error);

                return;
            }

            e.target.reset();

            callback.afterSuccessSubmit(response);
            
        });
    }

    return (
        <form onSubmit={props.handleSubmit ?? handleSubmit}>
            {props.children}
        </form>
    );
}

export default Form;