import "./index.scss";
import { useContext } from "react";
import Context from "../../Context";
import { Link } from "react-router-dom";
import Form from "../../Components/Form";
import Input from "../../Components/Form/Input";

const Footer = () => {
    const { resumo, loggedIn } = useContext(Context);
    return (
        <div id="footer">
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <Link to={"/"} title="Home"><img className="logo" src={`${localStorage.getItem('apiUrl')}imagens/medium/logo.svg`} alt="Logo" /></Link>
                        </div>
                        <div className="col-2">
                            <div className="f-menu">
                                <Link to={"/sobre"} title="Quem somos">Quem somos</Link>
                                <Link to={"/lojas"} title="Lojas">Lojas</Link>
                                {loggedIn && <Link to={"/profissionais"} title="Profissionais">Profissionais</Link>}
                                <Link to={"/contato"} title="Contato">Contato</Link>
                                {loggedIn && <Link to={"/"} title="Pontuação">Pontuação</Link>}
                            </div>
                        </div>
                        <div className="col-1">
                            <div className="social">
                                <a href={resumo.configuracoes.facebook} target="_blank" rel="noopener noreferrer" title="Facebook"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/fb_W.svg`} alt="Facebook" /></a>
                                <a href={resumo.configuracoes.instagram} target="_blank" rel="noopener noreferrer" title="Instagram"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/instagram_W.svg`} alt="Instagram" /></a>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="newsletter">
                                <p className="txt-white">Inscreva-se para receber nossa newsletter</p>
                                <Form>
                                    <input type="hidden" name="formulario" value="newsletter" />
                                    <Input size={12} label={"E-mail"} name={"email"} required={1} type={5} />
                                    <div className="submit">
                                        <div className="check">
                                            <input type="checkbox" name="lgpd" id="newsletter_lgpd" value="1" required={1} />
                                            <label htmlFor="newsletter_lgpd">
                                                <span className="checkbox"></span> <span>Li e estou de acordo com os <Link to="/termos-de-uso" title="Termos de uso">termos de uso</Link> e <Link to="/politica-de-privacidade" title="Política de privacidade">política de privacidade</Link>.</span>
                                            </label>
                                        </div>
                                        <button className="btn bg-primary txt-white" type="submit">OK</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="offset-1 col-2">
                            <div className="endereco">
                                <p className="txt-white">{resumo.configuracoes.endereco}</p>
                                <p className="txt-white">{resumo.configuracoes.bairro}, {resumo.configuracoes.cidade} - {resumo.configuracoes.estado}</p>
                                {resumo.configuracoes.telefone.length > 1 && <p className="txt-white">{resumo.configuracoes.telefone}</p>}
                                {resumo.configuracoes.whatsapp.length > 1 && <p className="txt-white">{resumo.configuracoes.whatsapp}</p>}
                                <p className="credito">© {new Date().getFullYear()} Polo AD - Todos os direitos reservados</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fin">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Footer;