import "./index.scss";
import { useContext, useEffect, useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import Context from "../../Context";
import CustomCursor from "../../Components/CustomCursor";
import axios from 'axios';

const Header = () => {
    const history = useHistory();
    
    const { loggedIn, setLoggedIn, setModal } = useContext(Context);
    const [open, setOpen] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [usuario, setUsuario] = useState({});

    const changeBackground = () => {
        if (window.scrollY >= 100) {
            document.querySelector("#header").classList.remove("transparent");
        } else {
            if (window.location.pathname === "/") {
                document.querySelector("#header").classList.add("transparent");
            }
        }
    }

    useEffect(() => {

        changeBackground();
        window.addEventListener("scroll", changeBackground);

        if (loggedIn) {

            let session = JSON.parse(localStorage.getItem('session'));

            if (session?.usuario && session?.codigo) {
                
                axios.get(localStorage.getItem('apiUrl') + 'check', {
                    params: {
                        id: session.usuario,
                        codigo: session.codigo
                    }
                }).then(response => {
        
                    if (response.data.status !== 200) {
        
                        window.alert(response.data.error);
            
                        return;
                    }

                    setUsuario(response.data.success)
        
                });
            }
        }

    }, []);

    const fakeBackground = (img = "") => {
        let fbackground = document.querySelector("#header .fake-background");
        if (img.length > 1) {
            fbackground.classList.add("active");
            fbackground.style.backgroundImage = `url(${localStorage.getItem('apiUrl')}imagens/large/${img}.webp)`
        } else {
            fbackground.classList.remove("active");
        }
    }

    const getMousePosition = (e, left = false) => {
        let cursor = document.getElementById(`header-cursor`);
        cursor.classList.add("active");
        cursor.style.top = (e.clientY - (75 / 2)) + "px";
        cursor.style.left = (e.clientX - (75 / 2)) + "px";
        if (left) {
            cursor.querySelector("img").style.transform = "rotate(180deg)";
        } else {
            cursor.querySelector("img").style.transform = "rotate(0deg)";
        }
    }

    const disableCustomCursor = () => {
        let cursor = document.getElementById(`header-cursor`);
        cursor.classList.remove("active");
    }

    const minhaConta = (evento) => {

        setOpen(false);

        if (loggedIn) {

            setDropdown(!dropdown);
            
            return;
        }

        window.location.href = 'https://polo.gvdsolucoes.com.br';
    }

    const logout = () => {

        localStorage.removeItem('session');

        setLoggedIn(false);

        setModal({
            titulo: "Sessão encerrada!",
            botao: "Voltar ao site"
        });
    }

    return (
        <div id="header" className={window.location.pathname === "/" ? "transparent" : ""}>

            <img onClick={() => setOpen(!open)} className="menu" src={`${localStorage.getItem('apiUrl')}imagens/medium/${open ? "close" : "menu"}.svg`} alt="Menu" />

            <Link onClick={() => setOpen(false)} to={"/"} title="Home"><img className="logo" src={`${localStorage.getItem('apiUrl')}imagens/medium/logo.svg`} alt="Logo" /></Link>

            <button className="conta" onClick={minhaConta}>

                <img src={`${localStorage.getItem('apiUrl')}imagens/medium/login.svg`} alt="Conta" />

                {dropdown &&
                    <div className="dropdown">
                        <div className="dropdown-usuario">
                            logado como
                            <span className="dropdown-usuario-nome">
                                {`${usuario.nome} ${usuario.sobrenome}`}
                            </span>
                        </div>

                        <div className="dropdown-menu">
                            <button onClick={logout} className="dropdown-menu-button">Sair</button>
                        </div>
                    </div>
                }

            </button>


            <div className={`menu-completo ${open ? "active" : ""}`}>
                <div className="fake-background"></div>
                <div className="items">
                    <Link onClick={() => setOpen(false)} to={"/sobre"} title="Quem somos" onMouseMove={(e) => { fakeBackground("menu_quemsomos"); getMousePosition(e) }} onMouseOut={() => { fakeBackground(); disableCustomCursor(); }}>Quem somos</Link>
                    <Link onClick={() => setOpen(false)} to={"/lojas"} title="Lojas" onMouseMove={(e) => { fakeBackground("menu_lojas"); getMousePosition(e) }} onMouseOut={() => { fakeBackground(); disableCustomCursor(); }}>Lojas</Link>
                    {loggedIn && <Link onClick={() => setOpen(false)} to={"/profissionais"} title="Profissionais" onMouseMove={(e) => { fakeBackground("menu_profissionais"); getMousePosition(e) }} onMouseOut={() => { fakeBackground(); disableCustomCursor(); }}>Profissionais</Link>}
                    <Link onClick={() => setOpen(false)} to={"/contato"} title="Contato" onMouseMove={(e) => { fakeBackground("menu_contato"); getMousePosition(e) }} onMouseOut={() => { fakeBackground(); disableCustomCursor(); }}>Contato</Link>
                    {loggedIn && <a onClick={() => setOpen(false)} href="http://polo.gvdsolucoes.com.br" title="Pontuação" onMouseMove={(e) => { fakeBackground("menu_pontuacao"); getMousePosition(e) }} onMouseOut={() => { fakeBackground(); disableCustomCursor(); }}>Pontuação</a>}
                </div>
            </div>
            <CustomCursor id={"header-cursor"} image="plus" />
        </div>
    )
};

export default withRouter(Header);



