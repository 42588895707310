import "./index.scss";

const CustomCursor = ({ id, image = "arrow_right" }) => {
    return (
        <div id={id} className="custom-cursor">
            <button>
                <img src={`${localStorage.getItem('apiUrl')}imagens/large/${image}.svg`} alt="arrowRight" />
            </button>
        </div>
    );
}

export default CustomCursor;