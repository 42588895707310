import { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Context from './Context';
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import axios from 'axios';
import Cookies from './Components/Cookies';
import TagManager from 'react-gtm-module';
import SEOHelper from './Components/SEOHelper';
import { setDefaultMetas } from "./Utils/SEOHelper";

const Home = lazy(() => import('./Pages/Home'));
const Sobre = lazy(() => import('./Pages/Sobre'));
const SecretSobre = lazy(() => import('./Pages/SecretSobre'));
const Lojas = lazy(() => import('./Pages/Lojas'));
const Profissionais = lazy(() => import('./Pages/Profissionais'));
const Loja = lazy(() => import('./Pages/Loja'));
const Contato = lazy(() => import('./Pages/Contato'));
const Login = lazy(() => import('./Pages/Login'));
const Texto = lazy(() => import('./Pages/Texto'));

function App() {
  const [resumo, setResumo] = useState({});
  const [modal, setModal] = useState(false);
  const [isRep, setIsRep] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const value = { loggedIn, setLoggedIn, resumo, setResumo, modal, setModal, activePage, setActivePage, menuOpen, setMenuOpen, cart, setCart, isRep, setIsRep };

  localStorage.setItem("apiUrl", "https://poload.com.br/sistema/api/");

  useEffect(() => {
    axios.get(localStorage.getItem("apiUrl") + "configuracoes").then(response => {
      if (response.data.success.seo && response.data.success.seo[0]) {
        setDefaultMetas(response.data.success.seo[0])
      }
      setResumo(response.data.success);
      if (response.data.success.configuracoes.tag_manager) {
        if (response.data.success.configuracoes.tag_manager.length > 5) {
          const tagManagerArgs = {
            gtmId: response.data.success.configuracoes.tag_manager
          }
          let checkCookies = setInterval(() => {
            let accept = localStorage.getItem("acceptCookiesDesnecessarios");
            if (accept) {
              TagManager.initialize(tagManagerArgs)
              clearInterval(checkCookies);
            }
          }, 3000);
        }
      }
    });

    let carrinho = JSON.parse(localStorage.getItem("carrinho"));
    if (!carrinho) { carrinho = [] }
    setCart(carrinho);

  }, []);

  const Sessao = ({ children }) => {

    useEffect(() => {

      let session = JSON.parse(localStorage.getItem('session'));
      if (session?.usuario && session?.codigo) {

        axios.get(localStorage.getItem('apiUrl') + 'check-session', {
          params: {
            id: session.usuario,
            codigo: session.codigo
          }
        }).then(response => {

          if (response.data.status !== 200) {

            window.alert(response.data.error);

            return;
          }

          setLoggedIn(true);
        });
      }

    }, []);

    return (
      <>
        { children }
      </>
    );
  }

  return (
    <div className="App">
      <HelmetProvider>
        <Context.Provider value={value}>
          <Sessao>
            <div id="modal" className={modal ? "active" : ""}>
              {modal && <div className="content">
                <h3>{modal.titulo}</h3>
                <p dangerouslySetInnerHTML={{ __html: modal.texto }}></p>
                <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
              </div>}
            </div>
            <h1 id="mainTitle" className='hidden'>PoloAD</h1>
            {resumo.textos &&
              <Router basename="/">
                <Header />
                <ScrollToTop setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
                {window.defaultMetas && <SEOHelper />}
                <Suspense fallback={<div className="suspense"></div>}>
                  <Switch>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route exact path="/sobre">
                      <Sobre />
                    </Route>
                    <Route exact path="/secret-sobre">
                      <SecretSobre />
                    </Route>
                    <Route exact path="/lojas">
                      <Lojas />
                    </Route>
                    <Route exact path="/lojas/:slug">
                      <Lojas />
                    </Route>
                    <Route exact path="/loja/:slug">
                      <Loja />
                    </Route>
                    <Route exact path="/profissionais">
                      <Profissionais />
                    </Route>
                    <Route exact path="/profissionais/:slug">
                      <Profissionais />
                    </Route>
                    <Route exact path="/contato">
                      <Contato />
                    </Route>
                    <Route exact path="/minha-conta">
                      <Login />
                    </Route>
                    {resumo.textos &&
                      <Route exact path="/termos-de-uso">
                        <Texto titulo={"Termos de uso"} texto={resumo.textos.termos_de_uso} />
                      </Route>
                    }
                    {resumo.textos &&
                      <Route exact path="/politica-de-privacidade">
                        <Texto titulo={"Política de privacidade"} texto={resumo.textos.politica_de_privacidade} />
                      </Route>
                    }
                  </Switch>
                </Suspense>
                <Cookies />
                <Footer />
              </Router>
            }
          </Sessao>
        </Context.Provider>
      </HelmetProvider>
    </div>
  );
}

export default App;
